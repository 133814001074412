.wrapper {
  position: relative;
  margin-bottom: 25px;
  width: calc(100% - 25px);
}

.searchInput {
  border-color: var(--marketplaceColor);
  padding-left: 50px;
  height: 40px;
}

.searchIcon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%) scale(0.8);
}
