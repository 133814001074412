.link {
  color: var(--marketplaceBlue);
  font-size: 22px;
  margin-top: 25px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.smallerSizeText {
  font-size: 18px;
}

.closeButton {
  color: var(--marketplaceBlue);
  transform: scale(1.4);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & span {
    margin: 0;
  }
}

.greeting {
  color: var(--marketplaceBlue);
  font-size: 22px;
  margin-top: 25px;

  & span {
    color: var(--marketplaceBlue);
    font-size: 22px;
    margin-top: 25px;
  }
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--colorGrey300);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    margin: 0 0 36px 0;
  }
}

.cartLinkWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 22px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: var(--boxShadowTop);
}

.cartLink {
  composes: buttonPrimary from global;
  border-radius: 0px;
  background-color: #7d8f9b;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  padding: 12px 0 12px 0;
  & svg {
    width: 25px;
    margin-right: 8px;
    height: 25px;
    & path {
      fill: #fff;
    }
  }
}

.divider {
  width: 100%;
  height: 150px;
}

.extraLinksSection {
  margin-top: 25px;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-top: 1px solid #dcdde0;

  & span {
    font-size: 18px;
    line-height: 23px;
  }
}

.cartIcon {
  fill: var(--marketplaceColor);
}
