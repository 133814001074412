@import '../../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 12px 0 0;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
  }
}

.rootWithoutHeight {
  /* Size */
  width: 100%;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 12px 0 0;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
  }
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
  margin-right: -188px;
}

.logoLink:hover {
}

.searchBtnWrapper {
  position: relative;
}

.iconWrapper {
  position: relative;
  z-index: 7;
  cursor: pointer;
}

/* Search */
.searchLink {
  min-width: 320px;
  height: 44px;
  border: 1px solid var(--colorBlack);
  border-radius: 10px;
  position: absolute;
  top: -5px;
  right: -5px;

  @media (max-width: 1260px) {
    min-width: 220px;
  }

  /* border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100); */
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.takeAvailableSpace {
  flex-grow: 1;

  /* At 1024px, the translations might need more space than what searchLink otherwise gets. */
  min-width: 220px;
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  padding-right: 16px;
  height: 44px;
  /* height: var(--topbarHeightDesktop); */

  @media (--viewportLarge) {
    padding-left: 16px;
  }
}

/* These is used with Inbox, Sign up, and Log in */
.topbarLink {
  flex-shrink: 0;

  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.topbarLinkLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:hover {
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-radius: 10px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuLink {
  composes: textSmall from global;
  position: relative;
  display: block;
  border: 0;
  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  text-align: left;
  transition: var(--transitionStyleButton);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;

  /* 211.111% */
  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
    background: var(--Dusty-Blue, #7d8f9b);

    & .menuItemBorder {
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.headerTopItems {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menuLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.logoWrapper {
  flex: 1;
  display: flex;
  justify-content: center;

  & a {
    padding: 0;
  }
}

.menuWrappers {
  display: flex;
  align-items: center;
  gap: 23px;
}

.categoryLink {
  color: #233541;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  /* 333.333% */
  letter-spacing: 1px;

  & .dropDownMenu {
    display: none;
  }

  &:hover {
    /* text-shadow: 0 0 0.65px #333, 0 0 0.65px #333; */
    & .dropDownMenu {
      display: block;
    }
  }
}

.dropDownMenu {
  background: #fff;
  /* box-shadow: 10px 16px 20px 0px rgba(0, 0, 0, 0.25); */
  position: absolute;
  left: 0;
  right: 0;
  padding: 50px 63px 41px;
  top: 137px;

  @media (max-width: 1400px) {
    padding: 50px 20px 41px;
  }
}

.dropDownItems {
  flex: 1.2;
  &:last-child {
    flex: 0.5;
  }

  & h3 {
    color: var(--Rose, #e17b8c);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px;
    /* 183.333% */
    letter-spacing: 1px;
    margin: 0 0 4px;
    padding: 0;
    text-align: left;

    @media (max-width: 1580px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  & li {
    font-size: 14px;
    line-height: 33px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1px;
    width: calc(50% - 47px);
    padding: 0;
    text-align: left;
    cursor: pointer;

    @media (max-width: 1580px) {
      font-size: 12px;
      line-height: 20px;
      width: 100%;
    }

    &:hover {
      text-decoration-line: underline;
    }
  }

  & ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    column-gap: 20px;

    @media (max-width: 1300px) {
      /* gap: 4px 24px; */
    }
  }

  & .seeAllLink {
    color: #e17b8c;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 1px;
    text-decoration-line: underline;
    margin-top: 5px;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }
}

.LgRow {
  flex: 1.7;
  & ul {
    & li {
      width: calc(25% - 47px);
      min-width: calc(25% - 47px);
    }
  }
}

.lastItem {
  /* max-width: 130px;
  transform: translateY(32px); */
  @media (max-width: 1300px) {
    /* max-width: 102px; */
  }

  & li {
    & h3 {
      color: #e17b8c;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 1px;
      text-decoration-line: underline;

      @media (max-width: 1300px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  & a {
    /* width: 100%; */
    color: #e17b8c;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 1px;
    text-decoration-line: underline;
  }
}

.link {
  color: #233541;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  /* 333.333% */
  letter-spacing: 1px;

  &:hover {
    text-shadow: 0 0 0.65px #333, 0 0 0.65px #333;
  }
}

.cartWrapper {
  position: relative;
  cursor: pointer;

  & .count {
    position: absolute;
    background-color: #e17b8c;
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    right: -10px;
    top: 20px;
    color: var(--Cream, #f8f4f3);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 0;
    /* 357.143% */
    letter-spacing: 1px;
  }
}

.brandsWrapper {
  justify-content: center !important;
}

.brands {
  max-width: 700px;
  & ul {
    & li {
      width: calc(24% - 12px);
    }
  }
}
