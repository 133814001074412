/* Position and sizing of burger button */
.bm-burger-button {
  margin-left: 20px;
  position: relative;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: transparent;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  width: 80% !important;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 10px 0;
  font-size: 1.15em;
  overflow: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE 10+ */
}

.bm-menu::-webkit-scrollbar {
  /* For Chrome, Edge, Safari */
  display: none;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 12px;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.5) !important;
  top: 0;
}

/* MUI ACCORDION */

.MuiPaper-root {
  box-shadow: none !important;
}

.css-jineix-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0 !important;
}

.MuiAccordionSummary-content {
  color: var(--marketplaceBlue) !important;
  font-size: 22px !important;
  margin-top: 25px !important;
  margin-bottom: 0 !important;
}

.MuiAccordionSummary-root {
  padding: 0 !important;
}

.MuiAccordionSummary-expandIconWrapper {
  margin-top: 15px !important;
}

.MuiPaper-root:before {
  display: none !important;
}
